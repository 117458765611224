import { gql } from '@apollo/client'

import { WoocommerceCart } from '@contracts/context/woocommerceCart'

import { client } from '@localApollo/client'

interface GetCartResponseType {
	cart: WoocommerceCart
}

export const getCustomerCart = async () => {
	const { data } = await client.query<GetCartResponseType>({
		query: GET_CART,
	})

	return data.cart
}

const GET_CART = gql`
	query GET_CART {
		cart {
			contents {
				itemCount
				productCount
				nodes {
					key
					product {
						node {
							id
							databaseId
							name
							description
							type
							onSale
							slug
							sku
							averageRating
							reviewCount
							productCategories {
								nodes {
									name
								}
							}
							image {
								id
								sourceUrl
								srcSet
								altText
								title
							}
							galleryImages {
								nodes {
									id
									sourceUrl
									srcSet
									altText
									title
								}
							}
							... on SimpleProduct {
								crossSell {
									nodes {
										id
										databaseId
										name
										type
										onSale
										slug
										image {
											id
											sourceUrl
											srcSet
											altText
											title
										}
										... on SimpleProduct {
											id
											price
											regularPrice
											salePrice
										}
									}
								}
								price
								regularPrice
								salePrice
							}
						}
					}
					variation {
						node {
							id
							databaseId
							name
							description
							type
							onSale
							price
							regularPrice
							salePrice
							image {
								id
								sourceUrl
								srcSet
								altText
								title
							}
						}
						attributes {
							id
							name
							value
						}
					}
					quantity
					tax
					total
					subtotal
					subtotalTax
				}
			}
			appliedCoupons {
				code
				description
				discountAmount
				discountTax
			}
			subtotal
			subtotalTax
			shippingTax
			shippingTotal
			total
			totalTax
			feeTax
			feeTotal
			discountTax
			discountTotal
			chosenShippingMethods
		}
	}
`

export default GET_CART
