import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React from 'react'

interface Props {
	children: React.ReactNode
}

export const PaypalProvider: React.FC<Props> = ({ children }) => {
	return (
		<PayPalScriptProvider
			options={{
				'client-id': process.env.GATSBY_PAYPAL_CLIENT_ID || 'test',
			}}
		>
			{children}
		</PayPalScriptProvider>
	)
}
