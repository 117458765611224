import React from 'react'

import { CustomerContextType } from '@contracts/context/customerContext'
import { WoocommerceUser } from '@contracts/context/woocommerceUser'

import { useGetCustomer } from '@hooks/useGetCustomer'

export const CustomerContext = React.createContext<CustomerContextType>([null, () => null])

interface Props {
	children: React.ReactNode
}

export const CustomerProvider: React.FC<Props> = (props) => {
	const [user, setUser] = React.useState<WoocommerceUser>(null)

	React.useEffect(() => {
		if (user) {
			return
		}

		// We don't have a user in the state, so let's check local storage
		const localCustomer = window?.localStorage.getItem('customer')

		// If it doesn't exist, set the user to undefined and stop
		if (localCustomer === null) {
			setUser(undefined) // Undefined means a check has been made, and there is no user

			return
		}

		// If it does exist, extract the customer from localStorage
		const customer = JSON.parse(localCustomer) as WoocommerceUser

		if (customer) {
			// Refetch our customer data from graphql
			useGetCustomer(customer.databaseId).then((data) => {
				setUser(data)
			})
		}
	}, [])

	return <CustomerContext.Provider value={[user, setUser]}>{props.children}</CustomerContext.Provider>
}
