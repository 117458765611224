import React from 'react'

type LocationType = 'GB' | string | null | undefined

type LocationContextType = [LocationType, React.Dispatch<React.SetStateAction<LocationType>>]

export const LocationContext = React.createContext<LocationContextType>([null, () => null])

interface Props {
	children: React.ReactNode
}

export const LocationProvider: React.FC<Props> = (props) => {
	const [location, setLocation] = React.useState<LocationType>(null)

	React.useEffect(() => {
		// Check to see if we have this stored in localStorage
		const storedLocation = localStorage.getItem('location')

		if (storedLocation) {
			setLocation(storedLocation as LocationType)
		} else {
			try {
				const getCountry = fetch('https://ipapi.co/country/').then((res) => res.text())

				getCountry.then((country) => {
					// country is the two-letter country code
					setLocation(country)

					localStorage.setItem('location', country)
				})
			} catch (error) {
				setLocation(undefined)
			}
		}
	}, [])

	return <LocationContext.Provider value={[location, setLocation]}>{props.children}</LocationContext.Provider>
}
