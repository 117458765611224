import React from 'react'

import { WoocommerceCart } from '@contracts/context/woocommerceCart'

import { getCustomerCart } from '@localApollo/queries/getCart'

import { WoocommerceContext } from './woocommerceContext'

interface Props {
	children: React.ReactNode
}

export const WoocommerceProvider: React.FC<Props> = (props) => {
	const [cart, setCart] = React.useState<WoocommerceCart>(null)
	const [cartLoading, setCartLoading] = React.useState<boolean>(true)
	const [justAdded, setJustAdded] = React.useState<boolean>(false)

	// Cart setup
	React.useEffect(() => {
		if (cart) {
			// We have a cart, so we can skip
			return
		}

		const localCart = window?.localStorage.getItem('woo-next-cart')

		// If it doesn't exist, fetch the cart from Woocommerce and set
		if (localCart === null) {
			getCustomerCart().then((data) => {
				setCart(data)
				setCartLoading(false)
			})

			return
		}

		// If it does exist, extract the cart data from local storage
		const cartData = JSON.parse(localCart) as WoocommerceCart

		setCart(cartData) // Set this in state so we can access data immediately
		setCartLoading(false)
	}, [])

	return (
		<WoocommerceContext.Provider
			value={{
				cart: [cart, setCart],
				cartLoading: [cartLoading, setCartLoading],
				justAddedToCart: [justAdded, setJustAdded],
			}}
		>
			{props.children}
		</WoocommerceContext.Provider>
	)
}
