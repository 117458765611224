import React from 'react'

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'

import { client } from './client'

interface Props {
	children: React.ReactNode
}

export const AppApolloProvider: React.FC<Props> = (props) => {
	const apolloClient =
		// @ts-ignore
		client instanceof ApolloClient ? client : new ApolloClient({ ...options, fetch, cache: new InMemoryCache() })

	return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
}
