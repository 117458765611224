// @ts-ignore
import fetch from 'isomorphic-fetch'
import { jwtDecode } from 'jwt-decode'
import isEmpty from 'lodash/isEmpty'

import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'

/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have an auth token in localStorage, add it to the GraphQL request as an authorization header.
 */
export const middleware = new ApolloLink((operation, forward) => {
	let headersData: any = null

	/**
	 * If session data exist in local storage, set value as session header.
	 */
	// @ts-ignore
	const session = typeof window !== 'undefined' ? localStorage.getItem('woo-session') : null

	if (!isEmpty(session)) {
		headersData = {
			'woocommerce-session': `Session ${session}`,
		}
	}

	/**
	 * If auth token exist in local storage, set value as authorization header.
	 */
	// @ts-ignore
	const auth = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('auth')) : null
	const token = !isEmpty(auth) ? auth.authToken : null

	if (!isEmpty(token)) {
		const decoded = jwtDecode<any>(token)

		const expireTime = new Date(decoded.exp * 1000).getTime()
		const now = Date.now()

		if (expireTime < now) {
			// @ts-ignore
			operation.setContext({
				headers: {},
			})

			return forward(operation)
		}

		headersData = {
			...headersData,
			authorization: token ? `Bearer ${token}` : '',
		}
	}

	if (!isEmpty(headersData)) {
		// @ts-ignore
		operation.setContext(({ headers = {} }) => ({
			headers: headersData,
		}))
	}

	return forward(operation)
})

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
	return forward(operation).map((response) => {
		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext()
		const {
			response: { headers },
		} = context

		const session = headers.get('woocommerce-session')

		if (session) {
			// Remove session data if session destroyed.
			if ('false' === session) {
				localStorage.removeItem('woo-session')

				// Update session new data if changed.
			} else if (localStorage.getItem('woo-session') !== session) {
				localStorage.setItem('woo-session', headers.get('woocommerce-session'))
			}
		}

		return response
	})
})

// Apollo GraphQL client.
export const client = new ApolloClient({
	link: middleware.concat(
		afterware.concat(
			createHttpLink({
				uri: `${process.env.GATSBY_WORDPRESS_PROTOCOL}://${process.env.GATSBY_WORDPRESS_URL}/graphql`,
				fetch: fetch,
			})
		)
	),
	cache: new InMemoryCache(),
})
