import { client } from '@localApollo/client'
import { GET_CUSTOMER, GetCustomerResponseType } from '@localApollo/queries/getCustomer'

export const useGetCustomer = async (id: number) => {
	const { data } = await client.query<GetCustomerResponseType>({
		query: GET_CUSTOMER,
		variables: {
			id,
		},
	})

	return data.customer
}
